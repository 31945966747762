.bg-footer {
    width: 100%;
   
    background: transparent linear-gradient(135deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #d0d7e2;
    border-radius: 50px;
    opacity: 1;
    color: #000000 !important;
  }
  
  .bg-footer-dark {
    width: 100%;
    
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px;
    opacity: 1;
    color: #fff !important;
  }
  
  @media only screen and (max-width: 979px) {
    .bg-footer {
      width: 95% !important;
     
      background: transparent linear-gradient(135deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #d0d7e2;
      border-radius: 50px;
      opacity: 1;
    }

    .bg-footer-dark {
      width: 95% !important;
   
      background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
        no-repeat padding-box;
      box-shadow: 5px 4px 16px #0c0c0c !important;
      border-radius: 50px;
      opacity: 1;
      color: #fff !important;
    }
  }
  

  .copy {
    position: fixed;
    bottom: 1rem;
    font-size: 0.9rem;
    font-weight: 300;
  }