.tabla-footer {
    table-layout: fixed;
    width:800px !important;
    margin-right: 0;
    margin-bottom: 0;
  }

  .tabla-footer  th {
  width: 60px;
  white-space: normal; 
}
  .tabla-footer 
td {
  width: 60px;
  white-space: nowrap; 
}


 #tdTarjeta{
  width: 120px;
 
  white-space: normal !important; 
}