.chartContainer {
    position: absolute;
    top: 490px;
    left: 577px;
    width: 189px;
    height: 189px;
    border: 21px dashed #B3C300;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}