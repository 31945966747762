.bg-criterios-asesores {
  width: 100%;
  background: transparent linear-gradient(94deg, #ffffff 0%, #e5e8ee 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 5px 4px 16px #d0d7e2;
  border-radius: 50px;
  opacity: 1;
}

.bg-criterios-asesores-dark {
  width: 100%;
  background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
    no-repeat padding-box !important;
  box-shadow: 5px 4px 16px #0c0c0c !important;
  border-radius: 50px;
  opacity: 1;
}

.icono-caja-criterios-asesores {
  width: 35px !important;
  height: 35px !important;
}

.bg-caja-criterios-asesores {
  width: 100%;
  height: 473px;
  background: transparent linear-gradient(153deg, #ffffff 0%, #e5e8ee 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 5px 4px 16px #d0d7e2;
  border-radius: 50px;
  opacity: 1;
}

@media only screen and (max-width: 1100px) {
  .bg-caja-criterios-asesores {
    width: 100%;
    height: 336px;
    background: transparent linear-gradient(153deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #d0d7e2;
    border-radius: 50px;
    opacity: 1;
  }
}

.bg-caja-criterios-asesores-dark {
  width: 100%;
  height: 473px;
  background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
    no-repeat padding-box !important;
  box-shadow: 5px 4px 16px #0c0c0c !important;
  border-radius: 50px;
  opacity: 1;
}

@media only screen and (max-width: 1100px) {
  .bg-caja-criterios-asesores-dark {
    width: 100%;
    height: 336px;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0%
      0% no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px;
    opacity: 1;
  }
}
