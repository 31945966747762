.bg-tabla-usuarios-liquidaciones {
  width: 100% !important;
  height: 100% !important;
  background: transparent linear-gradient(120deg, #ffffff 0%, #e5e8ee 100%) 0%
    0% no-repeat padding-box !important;
  box-shadow: 5px 4px 16px #d0d7e2 !important;
  border-radius: 50px !important;
  opacity: 1 !important;
}

.bg-tabla-usuarios-liquidaciones-dark {
  width: 100% !important;
  height: 100% !important;
  background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
    no-repeat padding-box !important;
  box-shadow: 5px 4px 16px #0c0c0c !important;
  border-radius: 50px !important;
  opacity: 1 !important;
}

@media only screen and (max-width: 900px) {
  .bg-tabla-usuarios-liquidaciones {
    width: 100% !important;
    height: 100% !important;
    background: transparent linear-gradient(120deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #d0d7e2 !important;
    border-radius: 50px !important;
    opacity: 1 !important;
  }

  .bg-tabla-usuarios-liquidaciones-dark {
    width: 100% !important;
    height: 100% !important;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0%
      0% no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px !important;
    opacity: 1 !important;
  }
}

.btn-publicar {
  width: 40px;
  height: 40px;
  background: #b4c400 0% 0% no-repeat padding-box !important;
  border-radius: 13px;
  opacity: 1;
}

@media only screen and (max-width: 1100px) {
  .btn-publicar {
    width: 39px;
    height: 39px;
    background: #b4c400 0% 0% no-repeat padding-box !important;
    border-radius: 13px;
    opacity: 1;
  }
}

@media only screen and (max-width: 500px) {
  .btn-publicar {
    width: 30px;
    height: 30px;
    background: #b4c400 0% 0% no-repeat padding-box !important;
    border-radius: 13px;
    opacity: 1;
  }
}

@media only screen and (max-width: 400px) {
  .btn-publicar {
    width: 30px !important;
    height: 30px !important;
    background: #b4c400 0% 0% no-repeat padding-box !important;
    border-radius: 13px;
    opacity: 1;
  }
}

.btn-publicar-numero {
  width: 50px;
  height: 40px;
  background: #b4c400 0% 0% no-repeat padding-box !important;
  border-radius: 13px;
  opacity: 1;
}

@media only screen and (max-width: 1100px) {
  .btn-publicar-numero {
    width: 39px;
    height: 39px;
    background: #b4c400 0% 0% no-repeat padding-box !important;
    border-radius: 13px;
    opacity: 1;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 500px) {
  .btn-publicar-numero {
    width: 35px;
    height: 39px;
    background: #b4c400 0% 0% no-repeat padding-box !important;
    border-radius: 13px;
    opacity: 1;
    font-size: 12px !important;
  }
}

.margin-left-btn-publicar {
  margin-left: 1.5rem !important;
}

@media only screen and (max-width: 1100px) {
  .margin-left-btn-publicar {
    margin-left: 0rem !important;
  }
}

@media only screen and (max-width: 900px) {
  .margin-left-btn-publicar {
    margin-left: 0rem !important;
  }
}

.margin-left-right-btn-publicar {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

@media only screen and (max-width: 900px) {
  .margin-left-right-btn-publicar {
    margin-left: 0.2rem !important;
    margin-right: 0.2rem !important;
  }
}

.btn-modal-nuevo-usuario-editar {
  width: 250px;
  height: 49px;
  /* UI Properties */
  background: #b4c400 0% 0% no-repeat padding-box !important;
  border-radius: 50px !important;
  opacity: 1;
}

.btn-modal-nuevo-completado {
  width: 250px;
  height: 49px;
  background: #E24444 0% 0% no-repeat padding-box !important;
  border-radius: 50px !important;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .centrado-responsive {
    margin: auto !important;
  }
}

.btn-guardar-usuario-editar {
  width: 164px !important;
  height: 45px !important;
  background: #b4c400 0% 0% no-repeat padding-box !important;
  border: 2px solid #b4c400 !important;
  border-radius: 29px !important;
  opacity: 1 !important;
}

.btn-cancelar-usuario-editar {
  width: 164px !important;
  height: 45px !important;
  border: 2px solid #292b2f !important;
  border-radius: 29px !important;
  opacity: 1 !important;
  color: #292b2f !important;
}

.label-buscador-cupones {
  width: 200px !important;
  height: 44px !important;
  background: #dde1e8 0% 0% no-repeat padding-box !important;
  box-shadow: inset 16px 11px 20px #d0d7e2 !important;
  color: #000000;
  border-radius: 50px !important;
  opacity: 1 !important;
  text-align: center;
}

.label-buscador-cupones-dark {
  width: 200px !important;
  height: 44px !important;
  background-color: #212121 !important;
  box-shadow: inset 5px 4px 15px #16181a !important;
  color: #fff;
  border-radius: 50px !important;
  opacity: 1 !important;
  text-align: center;
}
