.tabla-reclutamiento {
  width: 100% !important;
  height: 100% !important;
  background: transparent linear-gradient(134deg, #ffffff 0%, #e5e8ee 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 5px 4px 16px #d0d7e2;
  border-radius: 50px;
  opacity: 1;
}

.tabla-reclutamiento-dark {
  width: 100% !important;
  height: 100% !important;
  background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
  no-repeat padding-box !important;
box-shadow: 5px 4px 16px #0c0c0c !important;
  border-radius: 50px;
  opacity: 1;
}

.input-cargar-nueva-busqueda {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #d0d7e2 !important;
  border-radius: 50px !important;
  opacity: 1 !important;
}

.btn-ver-id-grafica{
  width: 60px !important;
  height: 36px !important;
  background: #B4C400 0% 0% no-repeat padding-box !important;
  border-radius: 28px !important;
  opacity: 1 !important;
  border: #B4C400 1px solid;
}

.btn-completado{
  width: 60px !important;
  height: 36px !important;
  background: #B4C400 0% 0% no-repeat padding-box !important;
  border-radius: 28px !important;
  opacity: 1 !important;
  border: #B4C400 1px solid;
}

.btn-pendiente{
  width: 60px !important;
  height: 36px !important;
  background: #E89F2F 0% 0% no-repeat padding-box !important;
  border-radius: 28px !important;
  opacity: 1 !important;
  border: #d0d7e2 1px solid;
}


@media only screen and (max-width: 1100px) {
  .btn-ver-id-grafica{
    width: 60px !important;
    height: 36px !important;
    background: #B4C400 0% 0% no-repeat padding-box !important;
    border-radius: 28px !important;
    opacity: 1 !important;
    border: #B4C400 1px solid;
  }
}

.label-buscador-reclutamiento-busqueda {
  width: 385px !important;
  height: 54px !important;
  background: #dde1e8 0% 0% no-repeat padding-box !important;
  box-shadow: inset 16px 11px 20px #d0d7e2 !important;
  color: #000000;
  border-radius: 50px !important;
  opacity: 1 !important;
  text-align: center;
}

.label-buscador-reclutamiento-busqueda-dark {
  width: 385px !important;
  height: 54px !important;
  background-color: #212121 !important;
  box-shadow: inset 5px 4px 15px #16181a !important;
  color: #fff;
  border-radius: 50px !important;
  opacity: 1 !important;
  text-align: center;
}

.label-buscador-reclutamiento-busqueda::-webkit-input-placeholder {
  text-align: center;
  line-height: 100px;
}

.label-buscador-reclutamiento-busqueda-dark::-webkit-input-placeholder {
  text-align: center;
  line-height: 100px;
}