.input-cargar-nueva-busqueda-observaciones {
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #d0d7e2 !important;
  border-radius: 30px !important;
  opacity: 1 !important;
}

.user-profile {
  display: flex;
  align-items: center;
  padding: 16px;
}

.user-photo img {
  width: 120px; 
  height: 120px;
  margin-right: 16px;
}

.user-info h2 {
  margin: 0;
}


.btn-ver-id-contratados {
  width: 36px !important;
  height: 36px !important;
  background: #b4c400 0% 0% no-repeat padding-box !important;
  border-radius: 13px !important;
  opacity: 1 !important;
  border: #b4c400 1px solid !important;
}
