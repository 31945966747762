/* archivo: ContenidoInicio.css */
.row {
    display: flex;
    flex-wrap: wrap;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Fuerza a las tarjetas a ocupar la misma altura */
}

.card-body {
    flex-grow: 1; /* Ocupa el espacio restante en la tarjeta */
}

.card-title {
    margin-bottom: 10px; /* Espaciado consistente entre el título y el contenido */
}

.card-text {
    margin-bottom: 0; /* Asegura que el texto no agregue espacio extra */
}