.star-width {
  width: 30px;
  height: 32px;
}

@media only screen and (max-width: 800px) {
  .star-width {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 500px) {
  .star-width {
    width: 30px;
    height: 32px;
  }
}

.contener-opinion-estrella {
  width: 100%;
  height: 210px;
  background: transparent linear-gradient(101deg, #ffffff 0%, #e5e8ee 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 5px 4px 16px #d0d7e2;
  border-radius: 50px;
  opacity: 1;
}

.contener-opinion-estrella-dark {
  width: 100%;
  height: 210px;
  
  box-shadow: 5px 4px 16px #0c0c0c !important;
  border-radius: 50px;
  opacity: 1;
}

.contenedor-opinion-estrella-mobile {
  width: 100%;
  height: 210px;
  background: transparent linear-gradient(101deg, #ffffff 0%, #e5e8ee 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 5px 4px 16px #d0d7e2;
  border-radius: 50px;
  opacity: 1;
}

.contenedor-opinion-estrella-mobile-dark {
  width: 100%;
  height: 210px;
  background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 5px 4px 16px #0c0c0c !important;
  border-radius: 50px;
  opacity: 1;
}

.opinion-texto-estrella {
  text-align: left;
  font: normal normal bold 20px/24px Lato !important;
  letter-spacing: 0px;
  color: #292b2f;
  opacity: 1;
}

.input-comentarios-calificar {
  width: 100%;
  height: 158px;
  background: #dde1e8 0% 0% no-repeat padding-box !important;
  box-shadow: inset 16px 11px 20px #d0d7e2 !important;
  border-radius: 50px !important;
  opacity: 1;
  resize: none;
}

.input-comentarios-calificar-dark {
  width: 100%;
  height: 158px;
  background-color: #212121 !important;
  box-shadow: inset 5px 4px 15px  #16181a!important;
  border-radius: 50px !important;
  opacity: 1;
  resize: none;
}

.input-comentarios-calificar-celular {
  width: 100%;
  height: 158px;
  background: #dde1e8 0% 0% no-repeat padding-box !important;
  box-shadow: inset 16px 11px 20px #d0d7e2 !important;
  border-radius: 30px !important;
  opacity: 1;
  resize: none;
}

.btn-enviar-comentario {
  width: 100%;
  height: 45px;
  background: #b4c400 0% 0% no-repeat padding-box;
  border: 2px solid #b4c400;
  border-radius: 29px;
  opacity: 1;
}

.btn-enviar-comentario-disabled {
  width: 100%;
  height: 45px;
  background: #d0d7e2 0% 0% no-repeat padding-box !important;
  border: 2px solid #d0d7e2;
  border-radius: 29px;
  opacity: 1;
}

.btn-enviar-comentario:hover {
  color: #000000;
  width: 100%;
  height: 45px;
  background-color: #000000 !important;
  border-radius: 28px !important;
  opacity: 1 !important;
  border: 0px solid #000000;
  cursor: pointer;
  transition: all 0.4s;
}

@media only screen and (max-width: 800px) {
  .btn-enviar-comentario {
    width: 100%;
    height: 45px;
  }

  .contenedor-opinion-estrella-mobile {
    width: 100%;
    height: 190px !important;
    background: transparent linear-gradient(101deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #d0d7e2;
    border-radius: 30px;
    opacity: 1;
  }

  .contenedor-opinion-estrella-mobile-dark {
    width: 100%;
    height: 190px;
    border-radius: 30px;
    opacity: 1;
  }
}

@media only screen and (max-width: 500px) {
  .contenedor-opinion-estrella-mobile {
    width: 100%;
    height: 210px !important;
    background: transparent linear-gradient(101deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #d0d7e2;
    border-radius: 30px;
    opacity: 1;
  }

  .contenedor-opinion-estrella-mobile-dark {
    width: 100%;
    height: 210px;
    border-radius: 50px;
    opacity: 1;
  }
}

.text-btn-enviar {
  width: 52px;
  height: 22px;
  text-align: center;
  font: normal normal bold 18px/22px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.centrado-flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.centrado-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.width-estrellas{
  cursor: pointer;
  font-size: 1.75rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media only screen and (max-width: 500px) {
  .width-estrellas{
    cursor: pointer;
    font-size: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
}

@media only screen and (max-width: 300px) {
  .width-estrellas{
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
}
