.circular-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.circular-progress {
    position: relative;
    width: 100px;
    height: 100px;
}

.circular-chart {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.circle-bg {
    stroke: #eee;
}

.circle {
    stroke: #B4C400;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s ease;
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: bold;
    color: #292B2F;
}

.progress-message {
    margin-top: 10px;
    font-size: 14px;
    color: #292B2F;
}