.iconos-modales {
  width: 29px;
  height: 33px;
}

.modalShadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem 3rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 25px #222;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modalShadowNotificaciones {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem 3rem;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 25px #222;
  backdrop-filter: blur(10px);
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modalShadowNotificacionesSinBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem 3rem;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 25px #222;
  /* backdrop-filter: blur(10px); */
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.centrado {
  display: grid;
  place-items: center;
}

.alineado-inicio {
  text-align: start !important;
}

.caja-popup {
  width: 75% !important;
  height: 585px;
  background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 5px 4px 16px #00000047;
  border-radius: 50px;
  opacity: 1;
}

.caja-popup-notificaciones {
  max-width: 45%; 
  max-height: 100% !important;
  padding: 2% 4%; 
  margin: auto;
  background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 5px 4px 16px #00000047;
  border-radius: 50px;
  opacity: 1;
}

.fs-23-18 {
  font-size: 23px !important;
}

@media only screen and (max-width: 900px) {
  .fs-23-18 {
    font-size: 18px !important;
  }
}

.fs-16-12 {
  font-size: 16px !important;
}

@media only screen and (max-width: 900px) {
  .fs-16-12 {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .caja-popup-notificaciones {
    max-width: 90%; 
    padding: 4%;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

@media only screen and (max-width: 1024px) {
  .caja-popup-notificaciones {
    max-width: 80%; 
    padding: 4%;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

@media only screen and (max-width: 800px) {
  .caja-popup-notificaciones {
    max-width: 75%; 
    padding: 4%;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

@media only screen and (max-width: 520px) {
  .caja-popup-notificaciones {
    max-width: 100%; 
    padding: 4%;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

@media only screen and (max-width: 410px) {
  .caja-popup-notificaciones {
    max-width: 100%; 
    padding: 4%;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

@media only screen and (max-width: 310px) {
  .caja-popup-notificaciones {
    max-width: 100%; 
    height: 100% !important ;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

@media only screen and (max-width: 1200px) {
  .caja-popup {
    width: 100% !important;
    height: 585px;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

@media only screen and (max-width: 1024px) {
  .caja-popup {
    width: 90% !important;
    height: 585px;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

@media only screen and (max-width: 800px) {
  .caja-popup {
    width: 100% !important;
    height: 585px;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

@media only screen and (max-width: 520px) {
  .caja-popup {
    width: 100% !important;
    height: 585px;
    background: transparent linear-gradient(129deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #00000047;
    border-radius: 50px;
    opacity: 1;
  }
}

.ocultar-texto-div {
  visibility: hidden !important;
}

.width-personalizado-form-popup {
  width: 85%;
}

@media only screen and (max-width: 900px) {
  .width-personalizado-form-popup {
    width: 100%;
  }
}

.swiper-pagination-bullet-active {
  opacity: 0.9 !important;
  background: #b4c400 !important;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b4c400 !important;
}
