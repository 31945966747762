/* Panel de control css */
.contenedor-panel-control {
    width: 100% !important;
    height: 130px;
    background: transparent linear-gradient(97deg, #ffffff 0%, #e5e8ee 100%) 0% 0%
      no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #d0d7e2 !important;
    border-radius: 50px !important;
    opacity: 1;
  }
  
  .contenedor-panel-control-dark {
    width: 100% !important;
    height: 130px;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px !important;
    opacity: 1;
  }

  .borde-caja-panel {
    border-right: 1px solid #d0d7e2 !important;
  }

.titulo-panel {
    text-align: center;
    font: normal normal 900 25px/27px DIN Next CYR;
    letter-spacing: 0px;
    color: #b4c400;
    opacity: 1;
  }
  
  .titulo-panel-bienvenido {
    text-align: center;
    font: normal normal 900 16px/19px DIN Next CYR;
    letter-spacing: 0px;
    color: #292b2f;
    opacity: 1;
  }
  
  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-image: url(../assets/svg/flechadropdown.svg);
    background-repeat: no-repeat;
    background-color: #b4c400 !important;
    border-radius: 28px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .select__control {
    width: 100% !important;
    height: 38px !important;
    background-color: #b4c400 !important;
    border: 2px solid #999;
    border-radius: 28px !important;
    font-size: 16px;
  }

  .select__control_12 {
    width: 100% !important;
    height: 44px !important;
    background-color: transparent !important;
    border: 2px solid #999;
    border-radius: 28px !important;
    font-size: 16px;
}

  .select__control_custom{
    width: 9vw !important;
  }
  .select__control3 {
    width: 100% !important;
    height: 44px !important;
    
    border: 2px solid #999;
    border-radius: 28px !important;
    font-size: 16px;
  }


   .select__control_custom2{
    width: 100% !important;
  }
  
  .select__control_custom-comparativo{
    width: 14vw !important;
  }

  .select__control_custom-inicio{
    width: 200px !important;
  }

  @media only screen and (max-width: 500px) {
    .select__control_custom-inicio{
      width: 94vw !important;
    }
  }

  
  .select__option:hover {
    background-color: #b4c400;
    color: white;
  }
  
  .select__option--is-selected {
    width: 100% !important;
    height: 44px !important;
    background-color: #b4c400 !important;
    color: white;
  }
  
  .select__menu {
    border: 2px solid #b4c400 !important;
    border-radius: 8px !important;
    background-color: #ffffff !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    width: 100% !important;
    overflow-y: auto;
    text-align: center;
    color: #0c0c0c;
  }
  
  .select-container .select__menu {
    max-height: 200px !important;
    overflow-y: auto !important; /* Habilita el desplazamiento vertical */
  }
  
  .select-container .select__menu::-webkit-scrollbar {
    width: 1px !important; /* Cambia el valor según tus preferencias */
  }
  
  /* Ajustar el ancho de la barra de desplazamiento horizontal */
  .select-container .select__menu::-webkit-scrollbar-horizontal {
    height: 1px !important; /* Cambia el valor según tus preferencias */
  }
  .select__control:focus {
    outline: none !important;
    border-color: #999 !important;
  }
  
  .select__control_custom:focus {
    outline: none !important;
    border-color: #999 !important;
  }

  .css-4sh82w-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: #999 !important;
   box-shadow: 0 0 0 1px #999 !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    text-align: center;
}



  
  .css-13cymwt-control:hover {
    border-color: #b4c400 !important;
  }
  
  .css-t3ipsp-control {
    border-color: #b4c400 !important;
    box-shadow: 0 0 0 0.1rem #000000 !important;
  }
  
  .btn-aplicar-select {
    width: 150px !important;
    height: 44px !important;
    background-color: #b4c400 !important;
    border-radius: 28px !important;
    opacity: 1 !important;
  }

  .ov-btn-slide-left {
    width: 150px !important;
    height: 40px !important;
    background-color: #b4c400 !important;
    border-radius: 28px !important;
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  .ov-btn-slide-left:hover {
    color: #fff;
  }
  .ov-btn-slide-left::after {
    content: "";
    background: #000000;
    position: absolute;
    z-index: -1;
    padding: 16px 20px;
    display: block;
    top: 0;
    bottom: 0;
    left: -100%;
    right: 100%;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .ov-btn-slide-left:hover::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }