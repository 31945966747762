.btn-comparativa {
    width: 145px;
    height: 40px;
    box-shadow: inset 11px 11px 11px #d0d7e2;
    border-radius: 28px;
    opacity: 1;
  }
  
  .btn-comparativa-dark {
    width: 145px;
    height: 40px;
    box-shadow: inset 11px 11px 11px #000000;
    border-radius: 28px;
    opacity: 1;
  }

  .bg-comparacion-ventas {
    width: 100%;
    height: 475px;
    background: transparent linear-gradient(133deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #d0d7e2;
    border-radius: 50px;
    opacity: 1;
  }
  
  .bg-comparacion-ventas-dark {
    width: 100%;
    height: 475px;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px;
    opacity: 1;
  }
  
  @media screen and (max-width: 1500px) {
    .bg-comparacion-ventas {
      height: 420px !important;
    }
  }
  
  @media only screen and (max-width: 1100px) {
    .bg-comparacion-ventas {
      width: 100% !important;
      height: 390px !important;
      background: transparent linear-gradient(133deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #d0d7e2;
      border-radius: 50px;
      opacity: 1;
    }
  
    .bg-comparacion-ventas-dark {
      width: 100%;
      height: 390px;
      background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #0c0c0c !important;
      border-radius: 50px;
      opacity: 1;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .bg-comparacion-ventas {
      width: 100% !important;
      height: 340px !important;
      background: transparent linear-gradient(133deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #d0d7e2;
      border-radius: 30px;
      opacity: 1;
    }
    .bg-comparacion-ventas-dark {
      width: 100%;
      height: 340px;
      background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #0c0c0c !important;
      border-radius: 50px;
      opacity: 1;
    }
  }
  @media only screen and (max-width: 600px) {
    .bg-comparacion-ventas {
      width: 100% !important;
      height: 380px !important;
      background: transparent linear-gradient(133deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #d0d7e2;
      border-radius: 30px !important;
      opacity: 1;
    }
    .bg-comparacion-ventas-dark {
      width: 100%;
      height: 380px;
      background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #0c0c0c !important;
      border-radius: 50px !important;
      opacity: 1;
    }
  }

  @media only screen and (max-width: 500px) {
    .bg-comparacion-ventas {
      width: 100% !important;
      height: 340px !important;
      background: transparent linear-gradient(133deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #d0d7e2;
      border-radius: 30px !important;
      opacity: 1;
    }
    .bg-comparacion-ventas-dark {
      width: 100%;
      height: 340px;
      background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #0c0c0c !important;
      border-radius: 30px !important;
      opacity: 1;
    }
  }

  .canvas-container {
    display: block;
    box-sizing: border-box;
    height: 158px !important;
    width: 256px;
  }