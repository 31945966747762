.bg-grafica {
    width: 100%;
    background: transparent linear-gradient(135deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #d0d7e2;
    border-radius: 50px;
    opacity: 1;
  }
  
  .bg-grafica-dark {
    width: 100%;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px;
    opacity: 1;
    color: white !important;
  }
  
  @media only screen and (max-width: 979px) {
    .bg-grafica {
      border-radius: 30px;
    }
  }
  
  .bg-grafica-celular {
    width: 100%;
    background: transparent linear-gradient(154deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 4px 16px #d0d7e2;
    border-radius: 30px;
    opacity: 1;
  }
  
  .bg-grafica-celular-dark {
    width: 100%;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 30px;
    opacity: 1;
  }
  
  @media only screen and (max-width: 579px) {
    .bg-grafica-celular {
      width: 100%;
      background: transparent linear-gradient(154deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #d0d7e2;
      border-radius: 30px;
      opacity: 1;
    }
  }

  .padding-top-grafica {
    padding-top: 100px !important;
  }

  .img-tarjeta {
    width: 66px;
    margin-top: 0;
  }

  .padding-top-grafica-analisis {
    padding-top: 40px !important;
  }